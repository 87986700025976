import React from 'react'

import Navbar from '../components/navbar'


import ServicesMain from '../components/Services Page/servicesMain'
import Footer from '../components/footer'

export default function Services() {
  return (
    <div>
        <Navbar/>
        <ServicesMain/>
        <Footer/>
        {/* <ServicesTwo/> */}
        
   
    </div>
  )
}
