import React from 'react'
import ServicesImg from '../../../static/images/servicesmain.jpg'

export default function servicesMain() {
  return (
    <div>

<section className=' px-4  lg:px-32 bg-white' style={{fontFamily:'poppins'}}>
  {/* <div class="skew skew-top mr-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
    </svg>
  </div> */}
  <div class="skew skew-top ml-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
    </svg>
  </div>
  <div class="py-14 lg:py-10 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto  lg:px-4">
      <div class="mb-10 flex flex-wrap">
        <div class="mb-10 lg:mb-0 w-full lg:w-1/2">
          <div class="max-w-xl pl-6 lg:pl-14">
            <span class="text-sky-600 font-semibold">Risk based analysis</span>
            <h2 class="mb-8 text-2xl lg:text-5xl font-bold font-heading text-indigo-900">Bespoke solutions for new age problems</h2>
            <p class="max-w-xl text-gray-500 leading-loose">RJ Actuaries & Consulting enables data driven risk assessments and provide solutions through technology driven applications</p>
          </div>
        </div>
        <div class="relative w-full lg:w-1/2">
          <img class="relative rounded-lg" src={ServicesImg} alt=""/>
          <button class="text-white hover:text-gray-50">
            {/* <svg class="absolute w-16 h-16" style={{top: '38%', left: '45%'}}>
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path>
            </svg> */}
          </button>
        </div>
      </div>
      <div class="flex flex-wrap pt-10 px-5 -mx-4">
        <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">1</span>
          <a class="mb-2 text-xl font-semibold font-heading text-indigo-800" href='/ActuarialConsulting'>Actuarial Consulting and Valuation</a>
          <p class="text-gray-500 leading-loose">Valuation of policyholder liabilities in an insurance company by preparation of regulatory & financial condition reports (FCR)</p>
        </div>
        <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">2</span>
          <a class="mb-2 text-xl font-semibold font-heading text-indigo-800" href='/EmployeeBenefits'>Employee Benefits and Consulting</a>
          <p class="text-gray-500 leading-loose">Valuation of employee benefit schemes & Preparation of reports based on IAs19, IndAs19, AS15, US GAAP and others</p>
        </div>
        <div class="w-full lg:w-1/3 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">3</span>
          <a class="mb-2 text-xl font-semibold font-heading text-indigo-800" href='/BusinessConsulting'>Business Consulting</a>
          <p class="text-gray-500 leading-loose">Providing business strategy services to clients and Building Enterprise Risk Management (ERM) framework and solutions
</p>
        </div>
      </div>

      <div class="flex flex-wrap pt-10 px-5 -mx-4">
        <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">4</span>
          <a class="mb-2 text-xl font-semibold font-heading text-indigo-800" href='/TechnologySolutions'>Technology Solutions</a>
          <p class="text-gray-500 leading-loose">Transforming business processes/systems to web/desktop applications and also Providing data warehousing and management services</p>
        </div>
        <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">5</span>
          <a class="mb-2 text-xl font-semibold font-heading text-indigo-800" href='/DataMining'>Data Mining & Engineering</a>
          <p class="text-gray-500 leading-loose">Transforming heaps of structured or unstructured data ready for analysis that can deliver progress and transformation</p>
        </div>
        <div class="w-full lg:w-1/3 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">6</span>
          <a class="mb-2 text-xl font-semibold font-heading text-indigo-800" href='/MachineLearningAI'>Machine Learning & AI</a>
          <p class="text-gray-500 leading-loose">Use case identification based on company’s strategic goals and business objectives and Support for advanced AI solutions</p>
        </div>
      </div>

    </div>
  </div>
  <div class="skew skew-bottom mr-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
    </svg>
  </div>
  <div class="skew skew-bottom ml-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
    </svg>
  </div>
</section>
    </div>
  )
}
